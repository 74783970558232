import React from "react";
import "./about.css";

const AboutSection = () => {
  return (
    <div className="about-section-main-container" id="home">
      <div
        className="col-12 about-firm-card p-3 shadow-lg bg-body-tertiary rounded"
        id="aboutSection"
      >
        <h1 className="about-firm-heading">About Firm</h1>
        <p className="about-firm-paragraph">
          <b>Dhamodhar and Associates</b> is a professionally managed firm
          catering to domestic clients with broad range of services in domestic
          taxation, regulatory and advisory services. <br />
          The firm was founded in 2022, under proprietorship of{" "}
          <span className="team-head-name">CA Dhamodhar Chary B. </span> <br />
          Our objective is to help our clients to focus on and achieve their
          business and financial goals by providing them services that is
          personalized and tailored to meet our client’s requirements, suit
          their business the best. <br /> Our firm is successfully catering to
          various clients across the industry like Information Technology & IT
          Enabled Services, Educational Institutions, Internet Service Industry,
          FMCG.
        </p>
      </div>
    </div>
  );
};

export default AboutSection;
