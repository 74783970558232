import React from "react";
import "./vision.css";

const OurVisionOurMission = () => {
  return (
    <div className="left-container">
      <div className="our-vision-and-mission p-3">
        <img
          src="https://static.vecteezy.com/system/resources/thumbnails/011/431/181/small/businessmen-brainstorming-searching-solution-business-strategy-vision-statement-business-mission-company-mission-business-planning-concept-vector.jpg"
          alt="lll"
          className="our-card-image"
        />
        <h1 className="about-firm-heading mt-1">Our Vision</h1>
        <p>
          To help our clients meet their goals through our people, services and
          solutions
        </p>
        <hr />

        <h1 className="about-firm-heading mt-1">Our Mission</h1>
        <p>
          Dhamodhar and Associates provides the people, services and solutions
          to the clients. Which leads to meet their business challenges and
          goals
        </p>
      </div>
    </div>
  );
};

export default OurVisionOurMission;
