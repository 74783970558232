import React from "react";
import "./team.css";

const TeamProfile = () => {
  return (
    <div className="right-container">
      <h1 className="about-firm-heading text-center mt-1">Team Profile</h1>
      <p className="team-head-para">
        <span className="team-head-name">B.DHAMODHAR CHARY </span>( Founder And
        Team Leader ) <span className="team-head-name">B.com ACA </span>
      </p>
      <div>
        <h1 className="proffesion-background mt-1">Professional Background</h1>
        <ul className="list-items">
          <li className="team-profile-list-item">
            Worked as Audit Manager in Bejugam & Co, Chattered Accountants for
            1.5 years.
          </li>
          <li className="team-profile-list-item">
            Handled Income Tax Audits, Statutory Audits, Return Fillings,
            Assessment Procedures.
          </li>
        </ul>
      </div>
      <hr />
      <p className="team-head-para">
        <span className="team-head-name">P JASWANTH </span>( Assistant Manager ){" "}
        <span className="team-head-name">CMA </span>
      </p>
      <div>
        <h1 className="proffesion-background mt-1">Professional Background</h1>
        <ul className="list-items">
          <li className="team-profile-list-item">
            Worked as Manager in Vitta Business Consultancy Private Limited.
          </li>
          <li className="team-profile-list-item">
            Handled Accounting Services to Companies, Payroll services and TDS &
            GST Compliances.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TeamProfile;
