import React, { useState } from "react";
import "./popup.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const ProvidingServices = [
  {
    id: "1",
    serviceName: "Tax Return Preparation",
    description:
      "Preparation and filing of personal and business tax returns, ensuring compliance with applicable tax laws and regulations.",
  },
  {
    id: "2",
    serviceName: "Bookkeeping",
    description:
      "Managing and recording financial transactions htmlFor businesses, including income, expenses, and account reconciliation.",
  },
  {
    id: "3",
    serviceName: "Payroll Management",
    description:
      "Processing employee payroll, calculating taxes and deductions, and ensuring compliance with labor laws.",
  },
  {
    id: "4",
    serviceName: "Financial Statement Preparation",
    description:
      "Creating accurate financial statements such as income statements, balance sheets, and cash flow statements htmlFor businesses.",
  },
  {
    id: "5",
    serviceName: "Tax Planning and Consulting",
    description:
      "Providing advice on tax strategies and planning to optimize tax efficiency and minimize liabilities.",
  },
  {
    id: "6",
    serviceName: "Audit and Assurance",
    description:
      "Conducting internal and external audits to verify the accuracy and integrity of financial records and statements.",
  },
  {
    id: "7",
    serviceName: "Business Strategy Consulting",
    description:
      "Offering insights and advice on business strategy, growth, and financial management to help clients achieve their goals.",
  },
  {
    id: "8",
    serviceName: "Identity Verification",
    description:
      "Verifying client identification documents and conducting Know Your Customer (KYC) checks htmlFor compliance with legal requirements.",
  },
  {
    id: "9",
    serviceName: "Financial Planning and Analysis",
    description:
      "Helping clients plan their financial future, analyzing budgets, and forecasting financial performance.",
  },
  {
    id: "10",
    serviceName: "Forensic Accounting",
    description:
      "Investigating financial discrepancies and potential fraud, and providing expert testimony in legal disputes.",
  },
];

const RequestPopup = () => {
  const [selectedServices, setSelectedServices] = useState([]);

  const handleCheckboxChange = (event, serviceId) => {
    const isChecked = event.target.checked;
    setSelectedServices((prevSelectedServices) => {
      if (isChecked) {
        return [...prevSelectedServices, serviceId];
      } else {
        return prevSelectedServices.filter((id) => id !== serviceId);
      }
    });
  };

  const FormSubmitted = (event) => {
    event.preventDefault();
    console.log(selectedServices);
  };

  return (
    <>
      <button
        type="button"
        className="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <span className="button__text">Request</span>
        <span className="button__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="white"
            className="bi bi-file-earmark-check-fill"
            viewBox="0 0 16 16"
          >
            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5 v-2l3 3h-2a1 1 0 0 1-1-1m1.354 4.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708.708" />
          </svg>
        </span>
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Service Request Form
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <form>
                <div className="mb-2">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Email ID
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <h5>Select Service</h5>
                <hr />
                <div className="check-box-container">
                  {ProvidingServices.map((eachService, index) => (
                    <div
                      key={index}
                      className="form-check container-of-checkbox"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheckDefault${index}`}
                        checked={selectedServices.includes(eachService.id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, eachService.id)
                        }
                      />
                      <label
                        className="form-check-label kalyanlabel"
                        htmlFor={`flexSwitchCheckDefault${index}`}
                      >
                        {eachService.serviceName}
                      </label>
                      {/* <hr /> */}
                    </div>
                  ))}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={FormSubmitted}
              >
                Submit Request
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestPopup;
