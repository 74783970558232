// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import "./App.css";
// import Navbar from "./Component/navbar";

// import AboutSection from "./Component/aboutUs/about";
// import CompanyTitle from "./Component/companyTitle/companytitle";
// import OurVisionOurMissionAndTeamProfile from "./Component/ourVisionAndTeam/margin";
// import WhyChooseUs from "./Component/whyChooseUs/whyus";
// import WeOffer from "./Component/weOffer/weoffer";
// import FooterSection from "./Component/footer/footer";
// import App22 from "./App22";

// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <Navbar />
//         <Routes>
//           <Route path="/" element={<App22 />} />
//           <Route path="/about" element={<AboutSection />} />
//           <Route path="/company-title" element={<CompanyTitle />} />
//           <Route
//             path="/our-vision"
//             element={<OurVisionOurMissionAndTeamProfile />}
//           />
//           <Route path="/why-choose-us" element={<WhyChooseUs />} />
//           <Route path="/we-offer" element={<WeOffer />} />
//           {/* Add more routes here as needed */}
//         </Routes>
//         <FooterSection />
//       </div>
//     </Router>
//   );
// }

// export default App;

import Navbar from "./Component/navbar";

import "./App.css";
import AboutSection from "./Component/aboutUs/about";
import CompanyTitle from "./Component/companyTitle/companytitle";
import OurVisionOurMissionAndTeamProfile from "./Component/ourVisionAndTeam/margin";
import WhyChooseUs from "./Component/whyChooseUs/whyus";
import WeOffer from "./Component/weOffer/weoffer";
import FooterSection from "./Component/footer/footer";

function App22() {
  return (
    <>
      <div className="App">
        <Navbar />
        <CompanyTitle />
        <AboutSection />
        <OurVisionOurMissionAndTeamProfile />
        <WhyChooseUs />
        <WeOffer />
        <FooterSection />
      </div>
    </>
  );
}

export default App22;
