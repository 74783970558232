import React from "react";
import "./companytitle.css";
import RequestPopup from "./popup";

const CompanyTitle = () => {
  return (
    <div className="company-title-container">
      <div className="company-sub-container">
        <h1 className="company-title">Dhamodhar and Associates</h1>
        <p className="welcome-para">
          <i>WELCOMES YOU....</i>
        </p>
        <RequestPopup />
      </div>
    </div>
  );
};

export default CompanyTitle;
