import React from "react";

import "./index.css";

function Navbar() {
  const notWorking = () => {
    alert("Currently this button is under Maintanace");
  };
  return (
    <nav className="navbar bg-body-tertiary fixed-top">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            src="https://seeklogo.com/images/C/ca-logo-94FF27E1C3-seeklogo.com.png"
            alt="logo"
            className="logo-image"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              Dhamodhar & Associates
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 w-100 ">
              <li className="nav-item-local " onClick={notWorking}>
                <a className="nav-link" aria-current="page" href="#aboutus">
                  About us
                </a>
              </li>
              <li className="nav-item-local" onClick={notWorking}>
                <a className="nav-link" href="#team">
                  Team Profile
                </a>
              </li>
              <li className="nav-item-local" onClick={notWorking}>
                <a className="nav-link" href="#whychooseus">
                  Why choose us?
                </a>
              </li>
              <li className="nav-item-local" onClick={notWorking}>
                <a className="nav-link" href="#weoffer">
                  We offer
                </a>
              </li>
              <li className="nav-item-local" onClick={notWorking}>
                <a className="nav-link" href="#contactus">
                  Contact Us
                </a>
              </li>
            </ul>
            <form className="d-flex mt-3" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Please enter mail id"
                aria-label="Search"
              />
              <button className="btn btn-outline-success" type="submit">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
