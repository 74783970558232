import React from "react";
import "./margin.css";
import OurVisionOurMission from "./vision";
import TeamProfile from "./team";

const OurVisionOurMissionAndTeamProfile = () => {
  return (
    <div className="marging-main-container">
      <div className="marging-sub-container">
        <OurVisionOurMission />
        <TeamProfile />
      </div>
    </div>
  );
};

export default OurVisionOurMissionAndTeamProfile;
