import "./whyUs.css";

const WhyChooseContentes = [
  {
    id: "1",
    content: "Believe in strong and regular communication with our clients",
  },
  {
    id: "2",
    content: "Ensure meeting the deadlines",
  },
  {
    id: "3",
    content: "Provide Experienced and Efficient services.",
  },
  {
    id: "4",
    content: "Abide by our commitment.",
  },
  {
    id: "5",
    content: "Consider feedback from our clients for us to improvise.",
  },
];

const WhyChooseUs = () => {
  return (
    <div className="why-section-main-container">
      <div className="why-firm-card shadow-lg bg-body-tertiary rounded">
        <h1 className="why-choose-us-heading">Why Dhamodhar and Associates</h1>
        <div className="scrolling-ofers-container">
          {WhyChooseContentes.map((eachItem) => (
            <div className="notification" key={eachItem.id}>
              <div className="notiglow"></div>
              <div className="notiborderglow"></div>
              <div className="notititle">Dhamodhar And Associates</div>
              <div className="notibody">{eachItem.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
