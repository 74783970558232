import "./footer.css";

const FooterSection = () => {
  return (
    <div className="footer-section pt-3 pb-3">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            {/* <img src="https://thumbs.dreamstime.com/b/chartered-accountant-certification-golden-stamp-printed-black-paper-background-d-illustration-202364636.jpg" className="food-munch-logo" />  */}
            <p className="thankyouwishes">Thankyou for visiting.. !</p>
            <h1 className="footer-section-mail-id">
              <a href="mailto:dhamodharca@gmail.com" className="mobile-number">
                {" "}
                dhamodharca@gmail.com
              </a>
            </h1>
            <p className="footer-section-address">
              # 20-1825, Papireddy Colony, Chandanagar, Serilingampally,
              Hyderabad,Telangana - 500 019.
              <br />
              Mobile:{" "}
              <a href="tel:9948195384" className="mobile-number">
                9948195384
              </a>
            </p>
          </div>
        </div>
        <div className="col-12 icons text-center mb-4">
          <a
            href="https://instagram.com/ca_dhamu_12?igshid=YmMyMTA2M2Y="
            className="icon icon--instagram"
          >
            <i className="bi bi-instagram"></i>
          </a>
          <a
            href="https://twitter.com/CADHAMODHAR?t=YUA2uyFb0OqN5akW8En4uw&s=09"
            className="icon icon--twitter mr-2 ml-2"
          >
            <i className="bi bi-twitter"></i>
          </a>
          <a className="icon icon--github" href="ds">
            <i className="bi bi-github"></i>
          </a>
          <a
            href="https://maps.app.goo.gl/PJvULnRHq5sSkzPH8"
            className="icon icon--location ml-2 mt-2"
          >
            <i className="bi bi-geo-alt"></i>
          </a>
        </div>
        <div className="iframe">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.5249642380236!2d78.330804574937!3d17.482436283421066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93caa84c64e5%3A0x95e2ec25282621c1!2sDHAMODHAR%20AND%20ASSOCIATES!5e0!3m2!1sen!2sin!4v1714716417302!5m2!1sen!2sin"
            width="450"
            height="300"
            // style="border:0;"
            allowFullScreen={true}
            title="Location"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
