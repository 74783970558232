import "./weofferitem.css";

const CollapsibleSection = ({ id, title, content, isOpen, onToggle }) => {
  const ArailExpended = isOpen ? "true" : "false";
  return (
    <div className="accordion-item mb-2 rounded">
      <h2 className="accordion-header">
        <button
          className={`rounded accordion-button ${isOpen ? "collapsed" : ""}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${id}`}
          aria-expanded={ArailExpended}
          aria-controls={`collapse${id}`}
          onClick={onToggle}
        >
          {title}
        </button>
      </h2>
      <div
        id={`#collapse${id}`}
        className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body text-start">{content}</div>
      </div>
    </div>
  );
};

export default CollapsibleSection;
