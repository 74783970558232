import { useState } from "react";

import CollapsibleSection from "../weOfferItemCreation/weofferitem";

import "./weoffer.css";

const WeOfferContent = [
  {
    id: 101,
    offerContent: "Statutory Audit as per Companies Act.",
    details:
      "A statutory audit involves auditing a company's financial statements as required by law, ensuring compliance with the Companies Act and other regulations.",
  },
  {
    id: 102,
    offerContent: "Internal Audits.",
    details:
      "Internal audits help evaluate the effectiveness of an organization's internal controls, risk management, and governance processes, ensuring operational efficiency and compliance.",
  },
  {
    id: 103,
    offerContent: "Tax Audits under the Income Tax Act.",
    details:
      "Tax audits are conducted to verify the accuracy of a company's tax returns and adherence to tax laws, ensuring proper tax compliance and avoiding penalties.",
  },
  {
    id: 104,
    offerContent: "GST Audit.",
    details:
      "GST audits are performed to verify the accuracy of a business's GST returns and compliance with GST laws, including verifying input tax credits and output tax liabilities.",
  },
  {
    id: 105,
    offerContent: "Stock Audit.",
    details:
      "Stock audits involve the verification of a company's inventory records, ensuring that stock levels match physical counts and accounting records.",
  },
  {
    id: 106,
    offerContent: "Indirect Tax Compliances.",
    details:
      "Indirect tax compliance services involve assisting businesses with their obligations under various indirect tax laws, such as GST and customs duties.",
  },
  {
    id: 107,
    offerContent:
      "Foreign Exchange Remittance Certifications such as Form 15CB/15CA.",
    details:
      "These certifications are required for international money transfers, ensuring compliance with foreign exchange regulations and tax laws.",
  },
  {
    id: 108,
    offerContent:
      "Transfer Pricing audit as per Section 92E of Income Tax Act.",
    details:
      "Transfer pricing audits review transactions between related parties to ensure that prices are set at arm's length, avoiding tax evasion and ensuring compliance with the law.",
  },
  {
    id: 109,
    offerContent:
      "Formation of Private Limited Companies, One Person Companies, LLPs.",
    details:
      "These services involve the process of setting up new business entities, including registration, documentation, and legal compliance for private limited companies, one-person companies, and LLPs.",
  },
  {
    id: 110,
    offerContent: "Accounting and Book-keeping Services.",
    details:
      "Accounting and bookkeeping services provide support with financial record-keeping, including maintaining ledgers, preparing financial statements, and reconciling accounts.",
  },
  {
    id: 111,
    offerContent: "Project Reports for Bank approvals.",
    details:
      "Project reports are detailed documents outlining the feasibility, risks, and potential returns of a project, often required by banks for loan approvals.",
  },
];

const WeOffer = () => {
  const [openItemId, setOpenItemId] = useState(null);

  const handleToggle = (id) => {
    if (openItemId === id) {
      setOpenItemId(null);
    } else {
      setOpenItemId(id);
    }
  };
  return (
    <div className="we-offer-section-main-container">
      <div className="we-offer-firm-card shadow-lg bg-body-tertiary rounded p-2">
        <h1 className="we-offer-text">We Offer </h1>
        <div className="accordion" id="accordionPanelsStayOpenExample">
          {WeOfferContent.map((eachOne) => (
            <CollapsibleSection
              id={eachOne.id}
              key={eachOne.id}
              title={eachOne.offerContent}
              content={eachOne.details}
              isOpen={openItemId === eachOne.id}
              onToggle={() => handleToggle(eachOne.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeOffer;
